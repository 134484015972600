@import 'src/assets/scss/customVariables';

.modal {
  .title {
    font-size: 1.25rem;
    font-weight: bold;
  }

  .body {
    display: flex;
    flex-direction: column;
    padding-top: 1.5rem;

    .sections {
      display: flex;
      align-items: flex-start;
      height: 65vh;

      /* sidebar */
      .first {
        height: 100%;
        flex: 1;
        border-right: 1px solid #434343;
        padding-right: 0.5rem;
        // overflow: auto;

        .sidebarSection {
          margin-bottom: 1rem;
        }

        .categoryItem {
          cursor: pointer;
          margin-bottom: 5px;
        }

        .categoryItem.active {
          font-weight: bold;
        }

        .categoryName {
          color: #b3b3b3;
          padding: 0.1rem 0;
        }
      }

      /* checkbox */
      .second {
        height: 100%;
        flex: 2;
        border-right: 1px solid #434343;
        padding: 0 1.5rem;

        .checkboxHeader {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;
          gap: 1rem;

          .allItems {
            display: flex;
            align-items: center;
            min-width: fit-content;
            line-height: normal;
            gap: 0.5rem;
            cursor: pointer;
          }

          .disabled {
            cursor: not-allowed;
            opacity: 0.4;
          }
        }

        .metricsTitle {
          margin-bottom: 1rem;
          color: #b3b3b3;
          font-weight: 600;
        }

        .filteredMetricsWrapper {
          overflow-y: auto;
          overflow-x: hidden;
          height: 55vh;
          padding-right: 1rem;

          .filteredMetrics {
            margin-bottom: 1rem;

            .metricTitle {
              font-weight: 600;
              padding-bottom: 0.5rem;
            }

            .metricCheckboxes {
              display: flex;
              justify-content: space-between;
              padding: 0.3rem 0;

              .metricName {
                color: #b3b3b3;
              }
            }
          }
        }
      }

      /* Drag & Drop */
      .third {
        height: 100%;
        flex: 1.5;
        padding-left: 1.5rem;

        .categories {
          overflow-y: auto;
          overflow-x: hidden;
          height: 62vh;
          margin-top: 0.5rem;
          padding-right: 1rem;

          .metricItem {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 0.5rem;
            margin: 0.5rem 0;
            padding: 0.5rem;
            height: 2rem;
            background-color: #373737;
            cursor: grab;
          }

          .metricItem:hover {
            cursor: pointer;
          }
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1rem;
    }
  }
}
