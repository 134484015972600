@import 'src/assets/scss/customVariables';

.mainContainer {
  background-color: $main_black_900;
  height: 100%;

  .navBar {
    z-index: 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 40px;
    position: fixed;
    width: 100%;
    top: 0;
    box-sizing: border-box;

    .logoAndMenu {
      display: flex;
      align-items: center;
      height: 48px;

      .logo {
        width: 96px;
        height: 3rem;
        margin-right: $spacer7;
        display: flex;
        align-items: center;

        img {
          max-height: 3rem;
        }
      }

      .menuItem {
        margin-right: 2.3rem;
        cursor: pointer;
        font-size: $text_sm;
      }

      .menuItemHighlighted {
        color: $green_yellow_500;
      }
    }

    .iconContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 20px;

      .notificationContainer {
        position: relative;
        width: 26px;

        img {
          width: 25px;
          height: 25px;
        }

        .notificationBackground {
          width: 12px;
          height: 12px;
          border-radius: 50px;
          position: absolute;
          top: -3px;
          right: 0px;
          display: flex;
          align-items: center;
          justify-content: center;

          .notification {
            background: $red_300;
            width: 8px;
            height: 8px;
            border-radius: 50px;
          }
        }

        .notificationWhite {
          background-color: $black_0;
        }

        .notificationBlack {
          background-color: $main_black_900;
        }
      }

      .profilePic {
        width: 40px;
        height: 40px;
        border-radius: 50px;
      }

      .userName {
        min-width: 6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;

        .dropDownIcon {
          margin-left: 0.5rem;
        }
      }
    }
  }

  .white {
    background: $black_10;
    box-shadow: 0px 2px 4px $main_black_900;
    color: $main_black_900;
  }

  .black {
    background: $black_700;
    color: $black_10;
    border-bottom: 1px solid #5e5e5e;
  }
}
