// needed to show dropdown in the table header filter
.ant-table-filter-dropdown {
  background-color: unset !important;
  box-shadow: none !important;
}

.ant-modal-content {
  background-color: #262626 !important;
}

// The sorting icons have been repositioned to prevent accidental clicks when adjusting the column width.
.ant-table-column-sorter,
.ant-table-column-sorter-full {
  padding-right: 0.6rem !important;
}

.ant-progress-inner {
  background-color: #434343 !important;
}

.ant-progress-bg {
  background-color: white !important;
}
