@import 'src/assets/scss/customVariables';

// New design (google)
.container {
  display: flex;
  height: 100%;
  width: 100%;

  .wrapperDescription {
    width: 50%;
    height: 100vh;
    background-color: $black_700;
    display: flex;
    align-items: center;
    justify-content: center;

    .description {
      width: 60%;

      .header {
        font-size: $text_xl;
        font-weight: 700;
        text-align: center;
        margin-bottom: 2.5rem;
        color: $black_0;
      }

      .section {
        margin-bottom: 2.5rem;

        .sectionTitle {
          color: $black_0;
          font-size: $text_tiny;
          font-weight: 500;
          margin-bottom: 8px;
        }

        .sectionDescription {
          color: #b3b3b3;
          font-size: $text_sm;
        }
      }
    }
  }

  .signUp {
    width: 50%;
    background-color: $main_black_900;
    display: flex;
    align-items: center;
    justify-content: center;

    .card {
      padding: 2rem 2rem;
      background-color: $black_700;
      border: 1px solid #5e5e5e;
      border-radius: 0.5rem;
      width: 40%;

      .content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        row-gap: 20px;
        width: 100%;

        .formButton {
          width: 100%;
        }

        .errorList {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          row-gap: 4px;

          .passwordRequirements {
            display: flex;
            align-items: center;
            column-gap: 6px;
            color: $black_0;

            .icon {
              width: 16px;
              height: 16px;

              * {
                fill: #7b7b7b;
              }
            }
          }
        }

        .inputLabelContainer {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .inputLabel {
            margin-bottom: 8px;
            font-size: $text_sm;
            display: flex;
            column-gap: 5px;
            color: $black_0;

            p {
              color: $black_60;
            }
          }

          .inputLink {
            text-decoration: underline;
          }
        }

        .expiredSession {
          display: flex;
          justify-content: center;
          font-weight: bold;
          align-items: center;
          padding-right: $spacer2;
          padding-left: $spacer2;
          color: $black_0;
        }

        .title {
          margin-bottom: $spacer3;
          font-weight: bold;
          font-size: 1.5rem;
        }
      }

      .loginTitle {
        text-align: center;
        color: $black_0;
        margin-bottom: 2rem;
        font-weight: 500;
        font-size: $text_tiny;
      }

      .googleBtn {
        background-color: $black_700;
        border: 1px solid #5e5e5e;
        width: 100%;
      }

      .linkContainer {
        display: flex;
        justify-content: center;
        column-gap: 12px;
        margin: 1rem 0;

        &Text {
          font-size: $text_tiny;
          color: $black_0;
        }

        .bottomLink {
          text-decoration: none;
          color: $green_yellow_500;
        }
      }

      .agreement {
        max-width: 340px;
        display: flex;
        color: #b3b3b3;

        .agreementStatement {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          column-gap: 3px;
        }

        .terms {
          cursor: pointer;
          text-decoration: underline;
          // margin-bottom: 0.5rem;

          &:hover {
            color: $fuchsia_500;
          }
        }
      }
    }
  }
}
