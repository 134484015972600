@import 'src/assets/scss/customVariables';

.wrapClassName {
  overflow: hidden !important;
}

.modal {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;

    .btnBack {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .btns {
      display: flex;
      align-items: center;

      > :first-child {
        margin-right: $spacer;
      }
    }
  }

  .body {
    background-color: #1e1e1e;
    margin: 0 -22px;

    .dna {
      margin: 0 auto;
      width: 67%;
      padding-top: 2rem;
      height: inherit;
      overflow-y: hidden;

      .sectionsNav {
        height: 2.5rem;
        margin-bottom: 2rem;
        display: flex;

        &Item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 25%;
          cursor: pointer;
          font-size: $text_sm;
          font-weight: 500;
          color: $black_0;
        }

        &ItemActive {
          cursor: initial;
          background-color: $black_500;
          border-radius: 0.25rem;
        }
      }

      .dnaContent {
        overflow-y: auto;
        height: calc(100vh - 11.5rem);
        display: flex;
        flex-direction: column;
        // gap: 1.5rem;

        &Card {
          background-color: $black_700;
          box-shadow: 0px 1px 2px 0px rgba(38, 38, 38, 0.06), 0px 1px 3px 0px rgba(38, 38, 38, 0.1);
          border-radius: 0.5rem;
          display: flex;
          flex-direction: column;
          gap: 1.25rem;
          padding: 1.5rem;
          margin-bottom: 1.5rem;

          .sectionTitle {
            font-weight: 700;
            font-size: $text_sm;
            color: $green_yellow_500;
            line-height: 1.25rem;
          }

          .sectionDescription {
            height: 4.25rem;
            width: 100%;
            font-weight: 500;
            font-size: $text_sm;
            line-height: 20px;
            background-color: $black_70;
            color: $black_60;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .group {
            display: flex;
            padding: 1.5rem 1rem;
            flex-direction: column;
            align-items: flex-start;
            gap: 1.5rem;
            background: $black_70;

            &FirstLine {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 0.5rem;

              &PgId {
                font-size: 1.875rem;
                font-weight: 600;
                line-height: 2.375rem;
                color: $black_0;
              }

              &Name {
                font-size: $text_tiny;
                line-height: 1.5rem;
                color: $black_30;
              }
            }

            &SecondLine {
              display: flex;
              align-items: center;
              gap: 3.125rem;

              &Item {
                display: flex;
                padding: 0.25rem 0rem;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                gap: 0.5rem;
                align-self: stretch;

                &Header {
                  color: $black_60;
                  font-size: $text_sm;
                  line-height: 1.25rem;
                }

                &Value {
                  color: $black_0;
                  font-size: $text_tiny;
                  line-height: 1.5rem;
                }
              }

              &Separator {
                width: 0.0625rem;
                height: 3.8125rem;
                background: $black_80;
              }
            }
          }

          .item {
            display: flex;
            align-items: center;
            min-height: 3rem;

            &Label {
              width: 16rem;
              margin-right: $spacer4;
              font-weight: 500;
              font-size: $text_sm;
              display: flex;
              align-items: center;
              align-self: center;
              gap: 0.5rem;
              color: $black_0;

              svg {
                fill: $black_60 !important;
              }
            }

            &LabelCentered {
              margin-top: 0.6rem;
            }

            &Info {
              font-size: $text_tiny;
              width: 100%;
            }

            .itemLabel {
              width: 20rem;
            }

            .itemInput {
              width: 100%;
              margin-right: auto;
              font-size: $text_tiny;

              .title {
                max-width: 48rem;
              }

              .author {
                display: flex;
                align-items: center;

                > :first-child {
                  margin-right: $spacer;
                }
              }

              .dropdown,
              .numberInput,
              .datePicker {
                width: 28rem;
              }

              .checkbox {
                justify-content: flex-start;
                width: min-content;
                white-space: nowrap;

                .firstSecond {
                  font-size: $text_tiny;
                  font-weight: 400;
                }

                .firstSecondActive {
                  font-size: $text_tiny;
                  font-weight: 700;
                }
              }

              .slider {
                width: 25rem;
              }

              .richEditor {
                height: 17rem;
                max-width: 48rem;
                border: 1px solid $black_100;
                border-radius: 8px;
                background-color: $black_500;
                color: $main_black_900;

                &Toolbar {
                  border: none;
                  border-top-right-radius: 8px;
                  border-top-left-radius: 8px;
                  margin: 0;
                  background-color: $black_700;

                  a {
                    color: $main_black_900;
                  }

                  div > div {
                    border: none;
                    background-color: $black_100;
                  }
                }

                &Editor {
                  max-height: 8rem;
                  margin: $spacer2;
                  color: $black_0;
                }

                &:hover {
                  border: 1px solid $black_40;
                  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                }
              }

              .textarea {
                width: 48rem;
              }

              .other {
                display: flex;
                align-items: center;

                &Input {
                  margin-left: $spacer4;
                  max-width: 20rem;
                }
              }

              .assetVariations {
                max-height: 26rem;
                max-width: 30rem;
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;

                &Item {
                  justify-content: flex-start;
                  margin-right: $spacer;
                  margin-bottom: $spacer;
                }
              }

              .visualFeatures {
                display: flex;
                flex-wrap: wrap;
                gap: 1.5rem;
              }
            }

            .firstSecondWrapper {
              padding: 1rem;
              border-radius: 0.4375rem;
              background: $violet_25;
              display: flex;
              flex-direction: column;
              gap: 1.5rem;
              width: 95%;
            }
          }

          .methodologySection {
            font-size: $text_tiny;
            font-weight: 700;
            margin-bottom: $spacer;
          }

          .ratingItem {
            margin-bottom: $spacer;
            display: flex;

            &Label {
              margin-bottom: $spacer;
            }

            .textarea {
              margin-left: 3rem;
              width: 36rem;
            }
          }

          .ctaPlacementClear {
            margin-left: 1rem;
            cursor: pointer;
            font-size: $text_sm;
            font-weight: 500;
            color: $violet_300;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        &Disabled {
          pointer-events: none;
        }
      }
    }
  }
}
